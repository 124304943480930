<template>
	<div class="main-content">
		<ktv-breadcrumb :title="$t('SME List', 'SME List')" :page="$t('Registration', 'Registration')"
			:folder="$t('SME', 'SME')"
		/>
		<ktv-table id="smelist" :columns="columns" :is-loading="isLoading" :rows="rows" :total-rows="totalRecords"
			:mode="modeTable" :filter="true" :search-custom-enabled="true"
			:search-placeholder="$t('Search by Real Name', 'Search by Real Name')" @on-column-filter="onColumnFilter"
			@on-page-change="onPageChange" @on-per-page-change="onPerPageChange" @on-search="onSearchFilter"
			@on-sort-change="onSortChange" @on-open-filter="openFilter()"
		>
			<template #columns="{ props }">
				{{ $t(props.column.label, props.column.label) }}
			</template>
			<template #actionleft>
				<ktv-button :text="$t('Add', 'Add')" icon="add" color="green-light" @click="openForm('Add')" />&nbsp;&nbsp;
				<ktv-button text="Export" icon="download" color="green-light" @click="exportSME()" />
			</template>
			<template #rows="{ props }">
				<span v-if="props.column.field == 'button' && props.row.EntityID">
					<b-dropdown size="xs" variant="link" toggle-class="text-decoration-none" no-caret>
						<template #button-content>
							<span>
								<img :src="icondropdown">
							</span>
						</template>
						<b-dropdown-item class="dropdownitem-action" @click="openForm('View', props.row)">
							<img :src="iconview" class="imgicon"> {{ $t('View', 'View') }}
						</b-dropdown-item>
						<b-dropdown-item class="dropdownitem-action" @click="openForm('Update', props.row)">
							<img :src="iconedit" class="imgicon"> {{ $t('Update', 'Update') }}
						</b-dropdown-item>
						<b-dropdown-item class="dropdownitem-action"
							@click="deleteData(props.row.EntityID, props.row.ObjectID)"
						>
							<img :src="icondelete" class="imgicon"> {{ $t('Delete', 'Delete') }}
						</b-dropdown-item>
						<b-dropdown-item v-if="props.row.isCognito != 'Register'" class="dropdownitem-action"
							@click="registerCognito(props.row)"
						>
							<img :src="iconcognito" class="imgicon"> {{ $t('Register Cognito', 'Register Cognito') }}
						</b-dropdown-item>
						<b-dropdown-item v-if="props.row.isCognito === 'Register'" class="dropdownitem-action"
							@click="openResetForm(props.row)"
						>
							<img :src="iconreset" class="imgicon"> {{ $t('Reset Password', 'Reset Password') }}
						</b-dropdown-item>
						<b-dropdown-item v-if="props.row.isCognito === 'Register' && props.row.RegisteredUserWeb != 'Yes'" class="dropdownitem-action"
							@click="registerUserLogin(props.row)"
						>
							<img :src="iconreset" class="imgicon"> {{ $t('Register User', 'Register User') }}
						</b-dropdown-item>
					</b-dropdown>
				</span>
				<span v-else-if="props.column.field == 'isCognito'">
					<b-badge v-if="props.row.isCognito == 'Register' && props.row.EntityID" href="#"
						variant="primary  m-2"
					>Yes</b-badge>
					<b-badge v-else-if="props.row.EntityID" href="#" variant="danger  m-2">No</b-badge>
				</span>
				<span v-else-if="props.column.field == 'RegisteredUserWeb'">
					<b-badge v-if="props.row.RegisteredUserWeb == 'Yes' && props.row.EntityID" href="#"
						variant="primary  m-2"
					>Yes</b-badge>
					<b-badge v-else-if="props.row.EntityID" href="#" variant="danger  m-2">No</b-badge>
				</span>
			</template>
		</ktv-table>

		<ktv-modal v-model="modalReset" :title="$t('Reset Password', 'Reset Password')" size="md" :centered="true"
			@close="modalReset = false"
		>
			<template #content>
				<b-form>
					<div class="form-group row mt-3">
						<label class="col-sm-4 col-form-label">{{ $t('Username', 'Username') }}</label>
						<div class="col-sm-8">
							<b-form-input v-model="reset.Username" type="text" class="form-control" readonly />
						</div>
					</div>
					<div class="form-group row mt-3">
						<label class="col-sm-4 col-form-label">{{ $t('Password', 'Password') }}</label>
						<div class="col-sm-8">
							<b-form-input ref="password" v-model="reset.Password"
								v-validate="'required|min:8|verify_password'" name="Password" data-vv-as="Password"
								type="password" class="form-control" :state="validateState('Password')"
							/>
							<b-form-invalid-feedback>
								{{ veeErrors.first('Password') }}
							</b-form-invalid-feedback>
						</div>
					</div>
					<div class="form-group row mt-3">
						<label class="col-sm-4 col-form-label" />
						<div class="col-sm-8">
							<b-form-input v-model="reset.PasswordConfirm" v-validate="'required|confirmed:password'"
								name="PasswordConfirm" type="password" data-vv-as="Password"
								:state="validateState('PasswordConfirm')" class="form-control"
							/>
							<b-form-invalid-feedback>
								{{ veeErrors.first('PasswordConfirm') }}
							</b-form-invalid-feedback>
						</div>
					</div>
				</b-form>
			</template>
			<template #footer>
				<div align="right">
					<ktv-button :text="$t('Reset', 'Reset')" color="green-dark" icon="save" @click="resetPassword()" />
				</div>
			</template>
		</ktv-modal>

		<!--		Modal Filter-->
		<ktv-modal
			v-model="modalsFilter"
			title="Filter"
			size="lg"
			@close="modalsFilter = false"
		>
			<template #content>
				<b-form>
					<div class="form-group row mt-3">
						<label class="col-sm-2 col-form-label">Partner</label>
						<div class="col-sm-4">
							<b-form-select
								v-model="filter.partnerID"
								:options="optionPartner"
							/>
						</div>
						<label class="col-sm-2 col-form-label">SME</label>
						<div class="col-sm-4">
							<b-form-select
								v-model="filter.smeCategoryID"
								:options="optionSMECat"
							/>
						</div>
					</div>
					<div class="form-group row mt-3">
						<label class="col-sm-2 col-form-label">Name</label>
						<div class="col-sm-10">
							<b-form-input
								v-model="filter.personName"
								type="text"
							/>
						</div>
					</div>
					<div class="form-group row mt-3">
						<label class="col-sm-2 col-form-label">Email</label>
						<div class="col-sm-10">
							<b-form-input
								v-model="filter.email"
								:state="validateState('email')"
								type="email"
							/>
						</div>
					</div>
					<div class="form-group row mt-3">
						<label class="col-sm-2 col-form-label">Phone Number</label>
						<div class="col-sm-10">
							<b-form-input
								v-model="filter.phoneNumber"
								type="number"
							/>
						</div>
					</div>
				</b-form>
			</template>
			<template #footer>
				<div align="right">
					<ktv-button
						text="Apply Filter"
						color="light"
						icon="filter"
						style="margin-right: 5px"
						@click="applyFilter()"
					/>

					<ktv-button
						text="Close"
						color="gray-light"
						style="margin-right: 5px"
						@click="modalsFilter = false"
					/>
				</div>
			</template>
		</ktv-modal>
	</div>
</template>
<script>
	import { KtvTable, KtvButton, KtvModal } from "@/components"
	import { mapActions } from "vuex"
	import * as XLSX from 'xlsx'

	export default {
		metaInfo: {
			title: "SME Registration",
		},
		components: { KtvTable, KtvButton, KtvModal },
		data() {
			return {
				/* eslint-disable global-require */
				iconedit: require("@/assets/images/icon/iconedit.png"),
				icondelete: require("@/assets/images/icon/icondelete.png"),
				iconview: require("@/assets/images/icon/iconview.png"),
				icondropdown: require("@/assets/images/icon/icondropdown.png"),
				iconcognito: require("@/assets/images/icon/iconcognito.png"),
				iconreset: require("@/assets/images/icon/iconcognito.png"),
				/* eslint-disable global-require */
				modalReset: false,
				isLoading: false,
				modeTable: "remote",
				columns: [
					{
						label: "Action",
						field: "button",
						html: true,
						tdClass: "text-center",
						thClass: "text-center",
					},
					{
						label: "SME ID",
						field: "ObjectID",
						thClass: "text-left",
					},
					{
						label: "Entity ID",
						field: "EntityID",
						thClass: "text-left",
					},
					{
						label: "Institution Name",
						field: "Name",
						thClass: "text-left",
					},
					{
						label: "Owner Name",
						field: "PersonName",
						thClass: "text-left",
					},
					{
						label: "Phone Number",
						field: "PhoneNumber",
						thClass: "text-left",
					},
					{
						label: "Last Login",
						field: "LastLogin",
						thClass: "text-left",
					},
					{
						label: "Last Activity",
						field: "LastActivity",
						thClass: "text-left",
					},
					{
						label: "is Cognito",
						field: "isCognito",
						thClass: "text-left",
						tdClass: "text-center"
					},
					{
						label: "User Web",
						field: "RegisteredUserWeb",
						thClass: "text-left",
						tdClass: "text-center"
					}
				],
				rows: [],
				totalRecords: 0,
				serverParams: {
					columnFilters: {
						partnerID: "",
						smeCategoryID: "",
						email: "",
						phoneNumber: "",
						personName: "",
					},
					sort: [
						{
							field: "",
							type: "",
						},
					],
					currentPage: 1,
					rowPerpage: 10,
				},
				options: {
					partner: [],
					yearEst: [],
					legalStatus: [],
					smeRole: [],
					objectType: [],
					education: [],
					gender: [],
					yesno: [],
					bank: [],
					bankBranch: [],
					relation: [],
				},
				reset: {
					Username: "",
					Password: "",
					PasswordConfirm: ""
				},
				modalsFilter: false,
				filter: {
					partnerID: '',
					smeCategoryID: '',
					email: '',
					phoneNumber: '',
					personName: '',
				},
				optionPartner:[],
				optionSMECat:[],
				serverParamsExport: {
					columnFilters: {
						partnerID: "",
						smeCategoryID: "",
						email: "",
						phoneNumber: "",
						personName: "",
					},
					sort: [{
						field: "",
						type: ""
					}],
					currentPage: 1,
					rowPerpage: 99999,
				},
				listSME: [],
			}
		},
		beforeCreate() {
			this.$http.Get([], this.$urlApi.sme.getPartner).then((res) => {
				this.options.partner = res.results
			})
			this.$http.Get([], this.$urlApi.sme.getYearEst).then((res) => {
				this.options.yearEst = res.results
			})
			this.$http.Get([], this.$urlApi.sme.getLegalStatus).then((res) => {
				this.options.legalStatus = res.results
			})
			this.$http.Get([], this.$urlApi.sme.getRole).then((res) => {
				this.options.smeRole = res.results
			})
			this.$http.Get([], this.$urlApi.sme.getObjectType).then((res) => {
				this.options.objectType = res.results
			})
			this.$http.Get([], this.$urlApi.sme.getEducation).then((res) => {
				this.options.education = res.results
			})
			this.$http.Get([], this.$urlApi.sme.getGender).then((res) => {
				this.options.gender = res.results
			})
			this.$http.Get([], this.$urlApi.sme.getCountry).then((res) => {
				this.options.countries = res.results
			})
		},
		mounted() {
			this.loadItems()
			this.getPartner()
			this.getSMERole()
		},
		methods: {
			...mapActions({
				pushData: "SME/pushData",
				removeData: "SME/deleteData",
				createCognito: "SME/createCognito",
				resetPwd: "SME/resetPasswordCognito",
				createUserLogin: "SME/createUserLogin"
			}),
			resetPassword() {
				this.$validator.validateAll().then((result) => {
					if (!result) {
						return
					}
					let payload = {
						data: [
							{
								type: "user",
								attributes: {
									Username: this.reset.Username,
									Password: this.reset.Password,
								}
							}
						]
					}
					this.$swal({
						title: "Are you sure?",
						text: "Password will be reset!",
						type: "question",
						showCancelButton: true,
						confirmButtonText: "Delete",
						showLoaderOnConfirm: true,
						preConfirm: () => {
							return this.resetPwd(payload)
								.then((res) => {
									return res
								})
								.catch((error) => {
									this.$swal.hideLoading()
									return error
								})
						},
						allowOutsideClick: () => !this.$swal.isLoading(),
					}).then((res) => {
						if (res.value.success) {
							this.$swal("Success!", "Password has been reset.", "success").then(() => {
								this.modalReset = false
							})
						} else {
							this.$swal("Error!", "Password failed to be reset.", "error")
						}
					})
				})
			},
			openResetForm(item) {
				this.reset.Username = item.Username
				this.modalReset = true;
			},
			deleteData(EntityID, ObjectID) {
				let payload = {
					EntityID: EntityID,
					ObjectID: ObjectID,
				}
				this.$swal({
					title: "Are you sure?",
					text: "Data will be deleted!",
					type: "question",
					showCancelButton: true,
					confirmButtonText: "Delete",
					showLoaderOnConfirm: true,
					preConfirm: () => {
						return this.removeData(payload)
							.then((res) => {
								return res
							})
							.catch((error) => {
								this.$swal.hideLoading()
								return error
							})
					},
					allowOutsideClick: () => !this.$swal.isLoading(),
				}).then((res) => {
					if (res.value.success) {
						this.$swal("Success!", "Data has been deleted.", "success").then(() => {
							this.loadItems()
						})
					} else {
						this.$swal("Error!", "Data failed to be deleted.", "error")
					}
				})
			},
			registerCognito(item) {
				let payload = {
					PhoneNumber: item.PhoneNumber,
					ObjectID: item.ObjectID,
					Email: item.Email,
					PersonName: item.PersonName,
					UserName: item.Username == null ? (item.Email).split('@')[0] : item.Username,
					EntityID: item.EntityID
				}
				this.$swal({
					title: "Are you sure?",
					text: "User will be registered in cognito.",
					type: "question",
					showCancelButton: true,
					confirmButtonText: "Register",
					showLoaderOnConfirm: true,
					preConfirm: () => {
						return this.createCognito(payload)
							.then((res) => {
								return res
							})
							.catch((error) => {
								this.$swal.hideLoading()
								return error
							})
					},
					allowOutsideClick: () => !this.$swal.isLoading(),
				}).then((res) => {
					if (res.value.success) {
						this.$swal("Success!", "User has been registered in cognito.", "success").then(() => {
							this.loadItems()
						})
					} else {
						this.$swal("Error!", "User failed to be registered in cognito.", "error")
					}
				})
			},
			openForm(opsi, items = []) {
				let data = {
					opsiDisplay: opsi,
					entityID: items.EntityID,
					objectID: items.ObjectID,
					options: this.options,
				}
				this.pushData(data)
				this.$router.push("/sme/MainForm")
			},
			updateParams(newProps) {
				this.serverParams = Object.assign({}, this.serverParams, newProps)
				this.serverParamsExport = Object.assign({}, this.serverParamsExport, newProps)
			},
			onPageChange(params) {
				this.updateParams({ currentPage: params.currentPage })
				this.loadItems()
			},
			onPerPageChange(params) {
				this.updateParams({ rowPerpage: params.currentPerPage })
				this.loadItems()
			},
			onSortChange(params) {
				this.updateParams({
					sort: [
						{
							type: params[0].type,
							field: params[0].field,
						},
					],
					currentPage: 1,
				})
				this.loadItems()
			},
			onColumnFilter(params) {
				this.updateParams({
					columnFilters: params,
				})
				this.loadItems()
			},
			onSearchFilter(event) {
				this.updateParams({
					columnFilters: { Name: event, PersonName: event },
					currentPage: 1,
					rowPerpage: 10,
				})
				this.loadItems()
			},
			loadItems() {
				this.isLoading = true
				this.$http
					.Post(this.serverParams, this.$urlApi.sme.list)
					.then((response) => {
						this.isLoading = false
						if (response.success) {
							this.totalRecords = response.results.total
							this.rows = response.results.data
							if (Object.keys(this.rows).length < 10 && Object.keys(this.rows).length !== 0) {
								this.rows = response.results.data
								for (let i = 0; Object.keys(this.rows).length < 10; i++) {
									this.rows.push({});
								}
							}
						} else {
							this.$swal("Error!", response.error_massage, "error")
						}
					})
					.catch((error) => {
						this.isLoading = false
						this.$swal("Error!", error.response.error_massage, "error")
					})
			},
			openFilter() {
				this.modalsFilter = true
				this.filter.partnerID = ""
				this.filter.smeCategoryID = ""
				this.filter.personName = ""
				this.filter.email = ""
				this.filter.phoneNumber = ""
			},
			applyFilter() {
				this.onColumnFilter(this.filter);
				this.loadItems()
				this.modalsFilter = false
			},
			getPartner() {
				this.$http
					.Get({}, this.$urlApi.sme.getPartner)
					.then((r) => {
						this.optionPartner = []
						r.results.forEach(async () => {
							this.optionPartner = r.results
						})
					})
					.catch(() => {
						// this.$swal("Error!", e.results.error_message, "error")
					})
			},
			getSMERole() {
				this.$http
					.Get({}, this.$urlApi.sme.getRole)
					.then((r) => {
						this.optionSMECat = []
						r.results.forEach(async () => {
							this.optionSMECat = r.results
						})
					})
					.catch(() => {
						// this.$swal("Error!", e.results.error_message, "error")
					})
			},
			exportSME() {
				this.$swal.fire({
					title: 'Loading...',
					allowOutsideClick: false,
					onBeforeOpen: () => {
						this.$swal.showLoading()
						this.listSME = []
						this.$http
							.Post(this.serverParamsExport, this.$urlApi.sme.list)
							.then((r) => {
								if (r.success) {
									r.results.data.forEach((e, key) => {
										this.listSME.push({
											No: key+1,
											SMEID: e.ObjectID,
											EntityID: e.EntityID,
											Institution_Name: e.Name,
											Owner_Name: e.PersonName,
											Email: e.Email,
											Phone_Number: e.PhoneNumber,
											Partner_Name: e.PartnerName,
											Country: e.CountryName,
											Province: e.ProvinceName,
											District: e.DistrictName,
											Sub_District: e.SubDistrictName,
											Village: e.VillageName,
											Last_Login: e.LastLogin,
											Last_Transaction: e.LastTransaction,
											Status: e.Status,
										})
									})
								} else {
									this.$swal("Error!", r.error_massage, "error")
								}
								this.exportToExcel()
							})
							.catch((e) => {
								this.$swal("Error!", e.response.data.error_message, "error")
							}).finally(() => this.$swal.close())
					}
				})
			},
			exportToExcel() {

				var dataSMEWS = XLSX.utils.json_to_sheet(this.listSME)

				var wb = XLSX.utils.book_new()

				XLSX.utils.book_append_sheet(wb, dataSMEWS, 'data_SME') // sheetAName is name of Worksheet

				const today = new Date();
				const date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
				const time = today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds();
				const dateTime = date +' '+ time;
				this.timestamp = dateTime;

				XLSX.writeFile(wb, 'FarmRetail_SME_'+this.timestamp+'.xlsx') // name of the file is 'book.xlsx'
			},
			registerUserLogin(item) {
				let payload = {
					UserRealName: item.PersonName,
					UserName: item.Username == null ? (item.Email).split('@')[0] : item.Username,
					RoleId: 1,
					Gender: "",
					PhoneNumber: item.PhoneNumber,
					CountryID: item.EntityCountryID,
					UserEmail: item.Email,
					EntityID: item.EntityID,
					UserPassword: "",
					PartnerID: "",
				}
				this.$swal({
					title: "Are you sure?",
					text: "User will be registered as a web user.",
					type: "question",
					showCancelButton: true,
					confirmButtonText: "Register",
					showLoaderOnConfirm: true,
					preConfirm: () => {
						return this.createUserLogin(payload)
							.then((res) => {
								return res
							})
							.catch((error) => {
								this.$swal.hideLoading()
								return error
							})
					},
					allowOutsideClick: () => !this.$swal.isLoading(),
				}).then((res) => {
					if (res.value.success) {
						this.$swal("Success!", "User has been registered as a web user.", "success").then(() => {
							this.loadItems()
						})
					} else {
						this.$swal("Error!", "User failed to be registered as a web user.", "error")
					}
				})
			},
		},
	}
</script>
